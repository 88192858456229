import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      style={{
        width:"100%",
        border:'1px solid #D2B3F7',
        borderRadius:'12px'
      }}
      height={119}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g style={{width:"100%"}} clipPath="url(#clip0_587_29245)">
        <rect
          style={{width:"100%"}}
          height={119}
          rx={12}
          fill="url(#paint0_linear_587_29245)"
        />
        <mask
          id="mask0_587_29245"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={16}
          y={16}
          width={32}
          height={32}
        >
          <rect x={16} y={16} width={32} height={32} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_587_29245)" />
        <path
          d="M629.55 96.4216L677.747 27.946L660.463 15.8106L624.421 67.0158L598.949 49.1319L586.793 66.4023L629.55 96.4216ZM652.004 161.08C627.023 159.235 604.875 148.967 585.559 130.275C566.244 111.583 554.307 89.1677 549.748 63.0292L538.77 0.0902725L616.012 -45.2771L704.052 -28.7373L715.029 34.2016C719.588 60.3402 715.944 85.473 704.097 109.6C692.25 133.727 674.886 150.887 652.004 161.08Z"
          fill="#9E63E7"
          fillOpacity={0.05}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_587_29245"
          x1={0}
          y1={59.5}
          x2={1087.12}
          y2={59.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset={1} stopColor="#EFE3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_587_29245"
          x1={15.2001}
          y1={19.195}
          x2={43.2001}
          y2={49.595}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.352732} stopColor="#9E63E7" />
          <stop offset={1} stopColor="#704C9C" />
        </linearGradient>
        <clipPath id="clip0_587_29245">
          <rect style={{width:"100%"}} height={119} rx={12} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )


export default SVGComponent;
