import React from 'react'
import { useState, useEffect } from 'react'
import { useAppDispatch } from '@/redux/store/hooks'
import { ActionMapper, NavigateType } from '@/types'
import { getActionPayload } from '@/utils/general/actions'
import { getApiDomain } from '@/utils/general/url'
import { getCompliancePolicyStatus } from '@/api/homepage'
import { Header, LOBS_VALUES, RAVEN_PAGE_NAMES, LoyaltyType } from "@cleartrip/ct-design-header"
import { SIGN_OUT_ROUTE } from '@/constants/paths'
import { IAccountDetailsResponse, IWalletResponse } from './type'
import { HOME_API_ENDPOINTS, REQUEST_METHODS } from '@/constants/network'
import { isEmpty } from '@/utils/general/browserHelper'
import baseFetch from '@/network/baseFetch'
import '@cleartrip/ct-design-header/dist/index.css';
import { ISupercoinAndLoyaltyResponse } from '@/utils/homepage/type'
import { extractSupercoinAndLoyaltyData } from '@/utils/homepage'
import ComplianceBanner from './ComplianceBanner'
import { Raven } from '@/analytics/raven'

const accountInfoURL = HOME_API_ENDPOINTS.ACCOUNT_DETAILS
interface HomeHeaderV2PropType {
    isSignedIn: boolean
}

const HomeHeaderV2 = ({ isSignedIn }: HomeHeaderV2PropType) => {
    const dispatcher = useAppDispatch()
    const [walletData, setWalletData] = useState<{ walletBalance: string | number, walletLoading: boolean }>({ walletBalance: 0, walletLoading: false })
    const [accountDetails, setAccountDetails] = useState({
        userInfo: {
            userName: '',
            userMobileNumber: '',
            countryCode: ''
        },
        accountDetailsLoading: false
    })
    const [supercoinAndLoyaltyData, setSupercoinAndLoyaltyData] = useState({
        supercoinbalance: 0,
        loyalty: ''
    })
    const [supercoinAndLoyaltyLoading, setSupercoinAndLoyaltyLoading] = useState(false)
    const [failedState,setFailedState] = useState({
        walletData: false,
        supercoin:false,
        loyalty:false
    })
    const [showComplianceBanner, setShowComplianceBanner] = useState(false)
    const loyaltyMapping: Record<string, LoyaltyType> = {
        FLIPKART_VIP: LoyaltyType.FkVip,
        FLIPKART_PREMIUM: LoyaltyType.FkPremium,
        MYNTRA_ELITE: LoyaltyType.InsiderElite,
        MYNTRA_ICON: LoyaltyType.InsiderIcon,
        CT_LOYALTY: LoyaltyType.CtLoyalty,
    };

    const handleLogin = () => {
        //Dispatch ation to open login modal
        dispatcher(getActionPayload('OPEN_MODAL', { type: ActionMapper.OPEN_LOG_IN_MODAL }))
    }
    const handleSignout = async () => {
        dispatcher(
            getActionPayload('NAVIGATE', {
                type: NavigateType.IN_PAGE_REDIRECT,
                url: SIGN_OUT_ROUTE
            })
        )
    }

    // this is for compliance banner
    async function getComplianceStatus() {
        try {
            const data = await getCompliancePolicyStatus()
            setShowComplianceBanner(!data?.isDisplayed)
        } catch (e) {
            console.log(e)
        }
    }

    const setLoyaltyFailure = (type:'supercoin' | 'loyalty' ) => {
        setFailedState(prev => ({...prev,[type]:true}))
    }
    useEffect(() => {
        const getWalletData = async () => {
            try{
                setWalletData(prev => ({ ...prev, walletLoading: true }))
                const walletData = await baseFetch<IWalletResponse>({
                    url: HOME_API_ENDPOINTS.HOME_WALLET_INFO,
                    method: REQUEST_METHODS.GET,
                    domain: getApiDomain()
                })
                const { wallet } = walletData || {}
                const walletBalance = wallet?.[0]?.balance ?? 0
                setWalletData({ walletBalance, walletLoading: false })
            }
            catch(e){
                setWalletData(prev => ({ ...prev, walletLoading: false }))
                setFailedState(prev => ({...prev,walletData:true}))
                console.error(e)
            }
        }
        const getAccountDetails = async () => {
            try{
                setAccountDetails(prev => ({ ...prev, accountDetailsLoading: true }))
                const accountDetailsResponse = await baseFetch<IAccountDetailsResponse>({
                    url: accountInfoURL,
                    method: REQUEST_METHODS.GET,
                    domain: getApiDomain(),
                    headers: {
                        caller: getApiDomain()
                    }
                })
                const accountDetails = {
                    userName: accountDetailsResponse?.travellerDetails?.[0]?.personalDetails?.firstName || '',
                    userMobileNumber: accountDetailsResponse?.mobile || '',
                    countryCode: accountDetailsResponse?.countryCode || ''
                }
                setAccountDetails({ userInfo: accountDetails, accountDetailsLoading: false })
            }
            catch(e){
                setAccountDetails(prev => ({ ...prev, accountDetailsLoading: false }))
                console.error(e)
            }
            
        }
        const supercoinAndLoyaltyData = async () => {
            try{
                setSupercoinAndLoyaltyLoading(true)
                const supercoinAndLoyaltyData = await baseFetch<ISupercoinAndLoyaltyResponse>({
                    url: HOME_API_ENDPOINTS.SUPERCOIN_AND_LOYALTY_INFO,
                    method: REQUEST_METHODS.GET,
                    domain: getApiDomain(),
                    headers: {
                        caller: 'AIR'
                    }
                })
                setSupercoinAndLoyaltyLoading(false)
                if(supercoinAndLoyaltyData?.message === 'FAILURE'){
                    setFailedState(prev => ({...prev,supercoin:true,loyalty:true}))
                    return

                }
                const { loyalty, supercoinbalance } = extractSupercoinAndLoyaltyData(supercoinAndLoyaltyData,setLoyaltyFailure)
                setSupercoinAndLoyaltyData({ supercoinbalance, loyalty })
            }
            catch(e){
                setSupercoinAndLoyaltyLoading(false)
                setFailedState(prev => ({...prev,supercoin:true,loyalty:true}))
                console.error(e)
            }
        }
        if (isSignedIn) {
            getWalletData()
            getAccountDetails()
            supercoinAndLoyaltyData()
        }
    }, [isSignedIn])
    useEffect(() => {
        getComplianceStatus()
    }, [])
    return (
        <>
            {showComplianceBanner && <ComplianceBanner setShowBanner={setShowComplianceBanner} />}
            <Header
                onLoginButtonClick={handleLogin}
                isUserLoggedIn={isSignedIn}
                handleLogoutClick={handleSignout}
                userDetailsLoading={accountDetails.accountDetailsLoading}
                userName={accountDetails.userInfo.userName}
                userMobileNumber={accountDetails.userInfo.userMobileNumber}
                countryCode={accountDetails.userInfo.countryCode}
                walletLoading={walletData.walletLoading}
                walletBalance={Number(walletData.walletBalance)}
                superCoinsLoading={supercoinAndLoyaltyLoading}
                fkSupercoinBurnBalance={supercoinAndLoyaltyData.supercoinbalance}
                loyaltyLoading={supercoinAndLoyaltyLoading}
                loyaltyType={loyaltyMapping[supercoinAndLoyaltyData.loyalty]  || ''}
                showAllLobs={true}
                selectedLOB={LOBS_VALUES.FLIGHTS}
                hideWalletChip={failedState.walletData}
                hideSupercoinsChip={failedState.supercoin}
                hideLoyaltyChip={failedState.loyalty}
                ravenPageName={RAVEN_PAGE_NAMES.FLIGHTS_HOME}
                ravenEventName='a_'
                ravenPushCallback={Raven.push} 
                loyaltyChipClick={() => { }}
                supercoinsChipClick={() => { }}
                walletChipClick={() => { }}
            />
        </>
    )
}

export default HomeHeaderV2