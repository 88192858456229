import React, { FC } from 'react'
import { Container } from '@cleartrip/ct-design-components'
import { HomeSearchContainer } from '@/components/oldComponents/Dropdown'

import './index.scss'
import { queryFormat } from '@/components/oldComponents/Dropdown/HomeSearchContainer'

export interface HomeFormType {
    query: queryFormat
    setQuery: (newQuery: Partial<queryFormat>) => void
    setShowVisaBanner: (arg1: boolean) => void
}

const HomeForm: FC<HomeFormType> = ({ query, setQuery, setShowVisaBanner }) => {
    return (
        <Container className="p-10 pt-8 w-100p">
            <HomeSearchContainer query={query} setQuery={setQuery} setShowVisaBanner={setShowVisaBanner} />
        </Container>
    )
}

export default HomeForm
