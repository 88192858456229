import React from 'react' 
import VisaDenialPopupBg from 'src/assets/svg/VisaDenialPopupBG.js'
import PopupInfo from 'src/assets/svg/VisaDenialPopupInfo.js'
function VisaDenialPopUp() {
  return (
    <div className='p-relative flex' style={{alignItems: 'center', justifyContent:'flex-end'}}>
      <div className='p-absolute flex flex-column' style={{ gap:'32px', marginRight:'10%'}}>
         <div>
            <PopupInfo/>
         </div>
        <a className='c-pointer' style={{textDecoration: 'none', fontSize:'16px', fontWeight: '600', lineHeight: '20px', color:'#0F6BFF'}} target='_blank' href='/all-offers/visa-denial-cover/'> Terms and conditions </a> 
      </div>
      <VisaDenialPopupBg/>
    </div>
  )
}

export default VisaDenialPopUp
