import * as React from "react";
const RightArrow = (props) => (
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16.5} r={16} fill="#1A1A1A" />
    <rect
      width={21.3333}
      height={21.3333}
      transform="translate(5.3335 5.83203)"
      fill="#1A1A1A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2642 10.4303C17.0595 10.2251 16.7213 10.2251 16.5077 10.4303C16.303 10.6356 16.303 10.9747 16.5077 11.1888L21.2779 15.9721H8.97856C8.68487 15.9721 8.44458 16.213 8.44458 16.5075C8.44458 16.802 8.68487 17.043 8.97856 17.043H21.2868L16.5344 21.8083C16.3208 22.0136 16.3208 22.3527 16.5344 22.5669C16.7391 22.7721 17.0773 22.7721 17.2909 22.5669L22.9511 16.8912C23.049 16.7931 23.1112 16.6503 23.1112 16.5164C23.1112 16.3826 23.0578 16.2398 22.9511 16.1416L17.2642 10.4303Z"
      fill="white"
    />
  </svg>
);
export default RightArrow;
