import { Container, Spacer } from '@cleartrip/ct-design-components'

import HomeForm from '@/components/home/HomeForm'
import { HOME_API_ENDPOINTS, REQUEST_METHODS } from '@/constants/network'
import useFetch from '@/network/useFetch'
import { useAppDispatch } from '@/redux/store/hooks'
import { NavigateType } from '@/types'
import { bannerDataType, BbdJson, IBanner } from '@/types/home'
import { getActionPayload } from '@/utils/general/actions'
import { getBannerData, getCarousalData, isFeatureEnabled, isFeatureEnabledOfferSlots } from '@/utils/general/uiHelper'
import { getflightsRecentSearch } from '@/utils/pages/home/recentSearch'
import CookieBanner from './CookieBanner'
import CarousalOffer from './CarousalHomePage'
import FlightDeals from './flightDeals'
import ImageGrid from './ImageGrid'
import FAQs from './SEOContent/FAQ'
import TripSuggestor from './TripSuggestor'
import { useState, useEffect } from 'react'
import { getScript, isEmpty } from '@/utils/general/browserHelper'
import RecentSearch from './RecentSearch'
import ClearChoiceMaxBannerContainer from './ClearChoiceMaxBanner'
import VisaDenialBanner from './VisaDenialBanner/index'
import { getApiDomain } from '@/utils/general/url'
import baseFetch from '@/network/baseFetch'
import FkImages from './FKImages'
// import ComplianceBanner from './ComplianceBanner'
// import { getCompliancePolicyStatus } from '@/api/homepage'
import { paidAffiliateTracker, paidChannelTracker } from '@/utils/pages/home'
import Clevertap from '@/analytics/clevertap/index'
import { ACTION_NAMES } from '@/constants/analytics'
import dynamic from 'next/dynamic'
import { queryFormat } from '../oldComponents/Dropdown/HomeSearchContainer'
import { deleteCookie, getCookie } from '@/utils/general/cookie'

interface Banner {
    format: null
}

interface Ext {
    slotid: string | undefined
}

interface MainObject {
    banner: Banner
    ext: Ext
}
const HomeMain = ({uhpCookie,data:response} : {uhpCookie : boolean | string,data:BbdJson[]}) => {
    /*
     * setting isLoaing's default value true to avoid one re-rendeer and to do client side actions after hydration.
     * so we can avoid the hydration error.
     */
    const [isLoading,setIsLoading] = useState(true)
    const [showVisaBanner, setShowVisaBanner] = useState(false)
    
    const recentSearchData = getflightsRecentSearch().splice(0,3)
    const [query, setQuery] = useState<queryFormat>({ adults: '1', childs: '0', infants: '0', class: 'Economy' })

    const [fkResponseImages, setFkResponseImages] = useState({})
    const [cookieBannerFlag, setCookieBanner] = useState(false)
    // const [showComplianceBanner, setShowComplianceBanner] = useState(false)

    const handleQueryChange = (newQuery: Partial<queryFormat>) => {
        setQuery(prevQuery => ({
            ...prevQuery,
            ...newQuery
        }))
    }

    let offerSlotData: BbdJson | undefined
    if (!isLoading && response) {
        offerSlotData = isFeatureEnabled(response)
    }
    
    useEffect(() =>{
        setIsLoading(false)
    },[])
    useEffect(() => {
        if (!isLoading && response) {
            let fkTempArray: MainObject[] = []
            let dummyObject = {
                banner: {
                    format: null
                },
                ext: {
                    slotid: ''
                }
            }
            offerSlotData?.fkSlots?.map((item, key) => {
                if (!isEmpty(item?.slotId)) {
                    dummyObject = {
                        banner: {
                            format: null
                        },
                        ext: {
                            slotid: item?.slotId
                        }
                    }
                    fkTempArray.push(dummyObject)
                }
            })
            fetchFkData(fkTempArray)
        }
    }, [isLoading, response])

    async function fetchFkData(fkTempArray: MainObject[]) {
        try {
            const SitePayload = {
                id: Math.random(),
                imp: fkTempArray,
                device: {
                    ua: window?.navigator?.userAgent,
                    geo: {}
                },

                site: {
                    id: '0',
                    name: 'CA_EN',
                    domain: getApiDomain()
                },
                wlang: ['en']
            }

            const response = await baseFetch<ResponseType>({
                body: JSON.stringify(SitePayload),
                url: HOME_API_ENDPOINTS.FK_ADS,
                method: REQUEST_METHODS.POST
            })

            if (response) {
                setFkResponseImages(response)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const dispatcher = useAppDispatch()

    // const redirectToCfnrLandingPage = () => {
    //   ravenHomeCfnrBannerClicked();
    //   window.open('https://offers.cleartrip.com/flights-clearchoice');
    // };

    // const cfnrHomeBannerUrl = '/offermgmt/flightsCommonImages/cfnr/clearchoice-home-banner.png';
    const [offerHook, setOfferHook] = useState({})
    const offerJSON = async () => {
        await getScript(
            `https://www.cleartrip.com/offermgmt/offerJson/${
                ['staging', 'development'].includes(process.env.NEXT_PUBLIC_CT_ENV || '') ? 'staging' : 'prod'
            }/offerSlot.js?` + new Date().getTime(),
            () => {
                setOfferHook(Math.random()) // Hack for BBD
            }
        )
    }

    useEffect(() => {
        offerJSON()
        // getComplianceStatus()
        if (window && window.localStorage && !window.localStorage.getItem('gdpr')) {
            setCookieBanner(true)
        }
        paidAffiliateTracker()
        paidChannelTracker()
        let affiliateutmExpiryDate = getCookie('affiliate_expdate')
        if (affiliateutmExpiryDate) {
            deleteCookie('affiliate_expdate')
        }
    }, [])
    const { humbgerSale } = isFeatureEnabledOfferSlots()

    const { desktopCarousels, mobileBannerList, loginBannerData } = offerSlotData || {}
    let carousalData: IBanner[] = []
    let bannerData: bannerDataType = []
    if (!isLoading && mobileBannerList?.length) {
        carousalData = getCarousalData(mobileBannerList!)
        bannerData = getBannerData(mobileBannerList!)
    }

    function pushCleverTap(name: string) {
        Clevertap.event('Air_UI_Action', {
            action_type: 'Link',
            action_name: name
        })
    }
    const handleHomePageBannerClick = (url?: string) => {
        if (url) {
            pushCleverTap(ACTION_NAMES.bannerHomepageBBD)
            dispatcher(
                getActionPayload('NAVIGATE', {
                    type: NavigateType.IN_PAGE_REDIRECT,
                    url
                })
            )
        }
    }
    function setGdpr() {
        window && window.localStorage && window.localStorage.setItem('gdpr', 'true')
        setCookieBanner(false)
    }
    // async function getComplianceStatus() {
    //     try {
    //         const data = await getCompliancePolicyStatus()
    //         setShowComplianceBanner(!data?.isDisplayed)
    //     }
    //     catch(e) {
    //         console.log(e)
    //     }
    // }
    return (
        <>
            {/* {showComplianceBanner && <ComplianceBanner setShowBanner={setShowComplianceBanner} />} */}
            <Container
                className="w-100p flex-1 pl-2 pr-2"
                onClick={setGdpr}
            >
                <Container marginLeft={'-8px'} marginRight={'-8px'}>
                    <Container style={{maxWidth:'1200px', margin:'auto'}} className="col-19 pl-6 pr-6">
                        <Container className="row" style={{flexWrap:"nowrap"}} marginLeft={'-8px'} marginRight={'-8px'}>
                            <Container className="flex-1 pl-2 pr-2">
                                <Container className="bg-white mt-7">
                                    <div
                                        style={{
                                            borderRadius: '16px 16px 16px 0px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '96px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                position:'relative',
                                            }}
                                        >
                                            <div className="pt-4">
                                                <h1 className="fs-9 c-neutral-900 fw-600" style={{ fontSize: '30px' }}>
                                                    Search flights
                                                </h1>
                                                <div className="flex flex-middle mt-2" style={{ minHeight: '20px' }}>
                                                    {humbgerSale?.flights && humbgerSale?.flights?.enabled && (
                                                        <div className="flex flex-middle" style={{ minHeight: '20px' }}>
                                                            {humbgerSale?.flights?.text ? (
                                                                <>
                                                                    <span className="dot ml-1"></span>
                                                                    <h2 className="ml-3 fs-16 c-neutral-900 fw-500">
                                                                        {humbgerSale?.flights?.text}
                                                                    </h2>
                                                                </>
                                                            ) : (
                                                                <h2 className="ml-1 fs-16 c-neutral-900 fw-500">
                                                                    {humbgerSale?.flights?.subHeading}
                                                                </h2>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                
                                            </div>  
                                        </div>
                                        <Container
                                            className="ba-solid bc-line-500 br-12 bg-neutral-100 p-relative"
                                            css={{
                                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.04)'
                                            }}
                                        >
                                            <HomeForm query={query} setQuery={handleQueryChange} setShowVisaBanner={setShowVisaBanner} />
                                        </Container>
                                    </div>
                                    {/**
                                     * Added mb-4 class here to adjust the removal of recent search widget
                                     * TODO: @flightsTeam remove this class once we enable RecentSearch widget
                                     **/}
                                    {/**
                                     * Commenting this for now and will add as per requirements
                                     * TODO: @flightsTeam
                                     **/}
                                    {/* <Container className={'mt-10 c-pointer mb-4'} onClick={redirectToCfnrLandingPage}>
                  <Image src={cfnrHomeBannerUrl} alt={'cancel for no reason banner'} width={706} height={80} />
                {/* </Container> */}
                                    <RecentSearch isLoading={isLoading} recentSearchResults={recentSearchData} />
                                    <Spacer className="mt-6" />

                                    <Spacer className="mt-6" />
                                    { showVisaBanner ? <VisaDenialBanner /> : <ClearChoiceMaxBannerContainer />}
                                </Container>
                            </Container>
                            <Container className="pl-2 pr-2">
                                <aside style={{width:'307px'}} className="ml-4 mt-7">
                                {!isLoading && (
                                        <>
                                            <CarousalOffer banners={carousalData} enabled={true} />
                                            <FlightDeals searchParams={query} desktopCarousels={desktopCarousels!} />
                                        </>
                                    )}
                                </aside>
                            </Container>
                        </Container>
                        {!isLoading && bannerData?.length ? (
                            bannerData.map(banner => (
                                <Container className="pb-4">
                                    <Container className="bg-white p-relative w-100p pb-4">
                                        <Container className="c-pointer">
                                            <Container className="bg-white p-relative w-100p pt-8">
                                                <Container className=" pl-0 pr-0 c-pointer">
                                                    <Container>
                                                        <ImageGrid
                                                            images={banner?.banners}
                                                            handleClick={handleHomePageBannerClick}
                                                            innerMargin={16}
                                                            key={`desktopflightMultiHomePageBanner`}
                                                            imageAlt="offer"
                                                        />
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                            ))
                        ) : (
                            <></>
                        )}
                        {!isEmpty(loginBannerData) && (
                            <div className="p-relative">
                                <Spacer className="pt-12" />
                                <a href="https://cleartrip.app.link/4ZfNFjig9vb">
                                    <div className="p-absolute btn-android"></div>
                                </a>
                                <a href="https://cleartrip.app.link/uy7d0Aauhwb">
                                    <div className="p-absolute btn-ios"></div>
                                </a>
                                <div>
                                    <ImageGrid
                                        images={loginBannerData!}
                                        innerMargin={16}
                                        handleClick={handleHomePageBannerClick}
                                    />
                                </div>
                            </div>
                        )}
                        {!isEmpty(fkResponseImages) && (
                            <div className="mt-12 c-pointer">
                                <FkImages fkResponseImages={fkResponseImages} />
                            </div>
                        )}
                        <Spacer className="pt-12" />
                        <TripSuggestor />
                        <FAQs />
                    </Container>
                </Container>
                <CookieBanner cookieBanner={cookieBannerFlag} onClick={setGdpr} />
            </Container>
        </>
    )
}

export default HomeMain
