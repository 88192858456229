import React from 'react'
import { Container, Typography } from '@cleartrip/ct-design-components'
import useFetch from '@/network/useFetch'
import TopDestinations from './PopularDestination'
import { getUrlWithArgs } from '@/utils/general/url'
import { ITripSuggestorResponse } from '@/types/home'
import './style.scss'
import TopDestinationsLoading from './LoadingComponents/TopDestinationLoader'
import { HOME_API_ENDPOINTS } from '@/constants/network'

const url = getUrlWithArgs(HOME_API_ENDPOINTS.TRIP_SUGGESTOR, 'popular_destinations')
const TripSuggestor = () => {
    const { response, isLoading } = useFetch<ITripSuggestorResponse>(url)
    const { popular_destinations, top_rated_flights, curated_flights } = response || {}

    return (
        <Container>
            <Typography variant="HM2" className="mb-6">
                Popular destinations
            </Typography>
            {isLoading || !popular_destinations ? (
                <TopDestinationsLoading />
            ) : (
                <TopDestinations flightsData={popular_destinations} />
            )}

            <Container height="60px" />
        </Container>
    )
}

export default TripSuggestor
