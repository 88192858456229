import React, { useState } from "react";
import VisaBannerBg from "./VisaBannerBackground";
import RightArrow from "src/assets/svg/RightArrow.js";
import VisaDenialLogo from "src/assets/svg/VisaDenialLogo.js"; 
import { Modal } from '@cleartrip/ct-design-components'
import VisaDenialPopUp from './VisaDenialPopUp'


function VisaBanner() {
    const [showPopup, setShowPopup] =useState(false)
  return ( <>
 
    <div className="p-relative flex flex-middle mt-5">
      <VisaBannerBg />
      <div className="p-absolute flex flex-between w-100p p-4">
        <div>
          <VisaDenialLogo/>
          <p style={{fontSize:'16px', fontWeight: '500', lineHeight: '22px'}}>
            Included for <span style={{color: '#11A670'}}>FREE!</span> Get a full refund on your flight fare in case your visa gets rejected. T&C Apply.{" "}
            <span className="c-pointer" style={{fontSize:'16px', fontWeight: '600', lineHeight: '24px', color:'#0F6BFF'}} onClick={()=>setShowPopup(true) }>Learn more</span> 
          </p>
        </div>
        <div className="flex flex-middle c-pointer" onClick={() => setShowPopup(true)}> 
            <RightArrow/>
        </div>
      </div>
    </div> 
    <Modal onClose={() => setShowPopup(false)} open={showPopup} hideCrossIcon={false} allowBackdropClose  modalContentCss={{ width: 'fit-content', borderRadius: '24px' }} >
        <VisaDenialPopUp/>
    </Modal>
    </>
  );
}

export default VisaBanner;
