import { ISupercoinAndLoyaltyResponse, ExtractedData } from "./type";

  /**
   * Extracts `supercoinbalance` from SUPERCOINS and `loyalty` from LOYALTY.
   *
   * @param {ISupercoinAndLoyaltyResponse} ISupercoinAndLoyaltyResponse - The API response object.
   * @returns {ExtractedData} - An object containing `supercoinbalance` and `loyalty`.
   */
export function extractSupercoinAndLoyaltyData(
    ISupercoinAndLoyaltyResponse: ISupercoinAndLoyaltyResponse, 
    setLoyaltyFailure: (type: 'supercoin' | 'loyalty') => void
): ExtractedData {
    const result: ExtractedData = { supercoinbalance: 0, loyalty: "" };
    try {
        ISupercoinAndLoyaltyResponse?.data?.forEach((item) => {
            if (item.instrument === "SUPERCOINS") {
                if (item.status === 'F') {
                    setLoyaltyFailure("supercoin");
                }
                const notionalValue = item.details.find(detail => detail.key === "notionalValue");
                if (notionalValue) {
                    result.supercoinbalance = Number(notionalValue.value);
                }
            }

            if (item.instrument === "LOYALTY") {
                if (item.status === 'F') {
                    setLoyaltyFailure("loyalty");
                }
                const cohort = item.details.find(detail => detail.key === "cohort");
                if (cohort) {
                    result.loyalty = cohort.value;
                }
            }
        });
        return result;
    } catch (e) {
        console.error(e);
        return result;
    }
}