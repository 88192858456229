
import React from 'react'
import { Container, Typography, Spacer } from '@cleartrip/ct-design-components'
import HomePageFaq, { ListCreator } from './HomePageFaq'
import { useRouter } from 'next/router'

const ctFeatures = [
    {
        heading: 'ClearChoice Max',
        value: ' Free cancellation or rescheduling for domestic (up to 24 hrs before departure) & international flights (up to 72 hrs before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date change or airline change up to 12 hrs (up to 24 hours for Air India*& Vistara*) before departure.'
    },
    {
        heading: 'Easy hotel cancellation',
        value: 'Cancel your hotel stay easily. Zero fees on hotel cancellations up to 24 hours before check-in on 20k+ hotels.'
    },
    {
        heading: 'Instant refund initiation',
        value: 'All refunds on flight and hotel cancellations are initiated instantly.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking easily on valid medical grounds and get up to ₹3500 against airline cancellation charges per passenger per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'Get stress-free coverage against a vast range of uncertainties for all international destinations at only ₹89 per user per day.'
    },
    {
        heading: 'Special Fares For Armed Personnel, Senior Citizens and Student travellers',
        value: 'Cleartrip introduces discounted fares for armed personnel, senior citizens and students on domestic flights. Additional discounts with coupon codes.'
    }
]

const ctFlightsFeatures = [
    {
        heading: 'ClearChoice Max',
        value: 'It offers free cancellation or rescheduling for domestic (up to 24 hours before departure) and international flights (up to 72 hours before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date or airline change up to 12 hours (or 24 hours for Air India* and Vistara*) before departure.'
    },
    {
        heading: 'Easy hotel cancellation',
        value: ' You can quickly cancel your hotel stay. There are no costs for hotel cancellations up to 24 hours before check-in at over 20,000 hotels.'
    },
    {
        heading: 'Instant refund initiation',
        value: 'All flight and hotel cancellation refunds are processed immediately.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking for sound medical reasons and receive up to INR 3500 against airline cancellation charges per person per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'For only INR 89 per user daily, you can get stress-free coverage against a wide range of uncertainties for all foreign destinations.'
    },
    {
        heading: 'Special fares for armed personnel, senior citizens, and student travellers',
        value: 'Cleartrip has introduced lower pricing on domestic flights for armed personnel, senior citizens, and students. Coupon codes provide further discounts.'
    }
]
const HOMEFAQData = [ 
    {
      heading: 'FAQs: Flights, Hotels, Bus and Train Booking on Cleartrip',
      values: [
        {
          question: 'What services does Cleartrip offer?',
          answer: {
            section1: 'Cleartrip is dedicated to making travel easy, economical, and uncomplicated, from international to domestic flights, from early morning to late night flights, and from budget to luxury flights. Cleartrip allows you to book your flight with just a few clicks. Our features include:',
            section2: [
               "ClearChoice Max offers free cancellation or rescheduling for domestic and international flights.",
               "ClearChoice Plus: Free date or airline change up to 12 hours before departure.",
               "Medical reasons to cancel your domestic travel, and receive up to INR3500 compensation against airline cancellation charges per person per segment."
            ],
            section3: ''
          }
        },
        {
          question: 'How do I create an account on Cleartrip?',
          answer: {
            section1: 'For all your travel bookings, sign up on Cleartrip.',
            section2: [
              "Go to the home page, click the link (top right), and enter your email address. We'll then send you an account activation link.",
              "Click it, and you'll be able to access your Cleartrip account."
            ],
            section3: 'The account activation link expires three days after you provide us with your email address, so please activate your account within that time frame.'
          }
        },
        {
            question: 'How can I contact Cleartrip customer support?',
            answer: {
              section1: 'You can reach Cleartrip Customer Support and Phone Number. Contact us at +91 9595333333. We are here to assist you with your booking and rescheduling queries.',
              
            }
        },
        {
            question: 'How do I search for flights on Cleartrip?',
            answer: {
              section1: 'We make it relatively simple to search and book Cleartrip flights',
              section2: [
                "Enter source and destination cities/airports.",
                "Select the date of travel.",
                "Select the number of travellers.",
                "Hit enter, and you're done! You have a search list of all available flights, sorted by price."
              ],
              
            }
        },
        {
            question: 'How do I search for hotels on Cleartrip?',
            answer: {
              section1: 'Booking a hotel online has never been easier than it is with Cleartrip.',
              section2: [
                "Click the 'hotels' tab on the site.",
                "Enter the city/locality/landmark/hotel name in the search bar.",
                "Enter the check-in and check-out dates.",
                "Choose the number of travellers and press enter."
              ],
              section3: 'You may also refine your hotel booking search results by utilising parameters such as price, star rating, traveller rating, facilities, and preferences such as hill-view or couple-friendly hotels. Cleartrip offers hotels for all types of stays. Cleartrip hotels are curated to give you the best value for your money.'
              
            }
        },
        {
            question: 'How do I book train tickets on Cleartrip?',
            answer: {
              section1: "Cleartrip enables you to buy train tickets and search for them. Simply click 'check availability' on the search results page, and you will find the book button to click for bookings. Booking Cleartrip trains is simple and fast",
            }
        },
      ]
    },
  ]
const FLIGHTSFAQData = [ 
    {
      heading: 'FAQs: Book Flights with Cleartrip',
      values: [
        {
          question: 'How can I check my flight status on Cleartrip?',
          answer: {
            section1: "You can check your flight status by entering your booking details on the 'Manage Booking' section of the Cleartrip website or app.",
          }
        },
        {
          question: 'Does Cleartrip offer refunds on cancelled tickets?',
          answer: {
            section1: "Yes, refunds are processed based on the airline's <a href='https://www.cleartrip.com/faq/account/tour/flights_cancellation.shtml' class='td-none' target='_blank'>cancellation policy</a>. Any applicable charges will be deducted.",
          }
        },
        {
            question: 'Can I book one-way and round-trip flights on Cleartrip?',
            answer: {
              section1: "Yes, Cleartrip allows you to book both one-way and round-trip flights as per your travel needs.",
            }
        },
        {
            question: 'Are there any additional charges on flight bookings?',
            answer: {
              section1: 'Cleartrip ensures transparent pricing with no hidden charges. Any applicable taxes or fees are displayed at checkout.',  
            }
        },
        {
            question: 'How do I get the best flight deals on Cleartrip?',
            answer: {
              section1: "You can check ongoing <a target='_blank' href='https://www.cleartrip.com/all-offers/' class='td-none'>offers</a>, set fare alerts, and book in advance for the best flight prices." 
            }
        },
        {
            question: 'Can I modify my flight booking?',
            answer: {
              section1: "Yes, you can make flight modifications, such as changing the date or time, subject to airline policies and fees.",
            }
        },
        {
            question: 'What payment methods does Cleartrip accept?',
            answer: {
              section1: "Cleartrip accepts multiple payment methods, including credit/debit cards, UPI, net banking, and digital wallets.",
            }
        },
        {
            question: 'Is it safe to book flights on Cleartrip?',
            answer: {
              section1: "Absolutely! Cleartrip uses secure encryption technology to protect your payment and personal details.",
            }
        },
        {
            question: 'Can I select my seat while booking?',
            answer: {
              section1: "Yes, many airlines allow seat selection during booking or after ticket confirmation.",
            }
        },
        {
            question: "How will I receive my e-ticket?",
            answer: {
              section1: "Once you confirm your booking, we will send your e-ticket to your email. You can also access it on the Cleartrip app.",
            }
        },
      ]
    },
  ]

const flightsDiscalimerData = {
  type: 'list',
  values: [
    {
      type: 'text',
      value: "Flight fares are dynamic and subject to availability at the time of booking."
    },
    {
      type: 'text',
      value: "Refunds, cancellations, and modifications are as per the airlines policies."
    },
    {
      type: 'text',
      value: "Before the final payment, we will display any additional charges, taxes, or surcharges."
    },
    {
      type: 'text',
      value: "Cleartrip is a travel aggregator and does not operate its own flights."
    },
    {
      type: 'text',
      value: "Discounts and offers are subject to the terms and conditions specified on the website."
    }
  ],
}


const FAQ = ({ data = [] }) => {
    return (
      <Container>
        {data.map((faq, index) => (
          <div key={index} className="pt-8"> 
            <h2 className="pb-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
              {faq.heading}
            </h2>
            {faq?.values?.map((item, subIndex) => (
              <div key={subIndex}>
                {item?.question && <h2 className="pt-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                  {item?.question}
                </h2>}
                {item?.answer?.section1 && <p className="pt-4 c-dark-grey fs-14" dangerouslySetInnerHTML={{ __html: item?.answer?.section1 }}/>}
                {item?.answer?.section2 && (
                  <ul className="px-6 pt-2" style={{ listStyleType: 'decimal' }}>
                    {item.answer.section2.map((listItem, itemIndex) => (
                      <li key={itemIndex} className="pt-1 c-dark-grey fs-14">
                        {listItem}
                      </li>
                    ))}
                  </ul>
                )}
                {item?.answer?.section3 && (
                  <p className="pt-2 c-dark-grey fs-14">{item.answer.section3}</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </Container>
    );
  };

const FAQs = () => {
    const router = useRouter()
    const { pathname } = router

    return (
        <>
            <Container className="pt-5" />
            {pathname === '/flights' ? (
                <>
                <div>
                    <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Book Flights with Cleartrip - Seamless, Affordable & Hassle-Free</h3>
                    <p className="pt-4 c-dark-grey fs-14">
                      Planning your next trip? Whether it's a domestic journey within India or an international adventure, Cleartrip makes it simple to <a href='https://www.cleartrip.com/flights' className='td-none'> book cheap flights</a> without any hassle.
                    </p>
                    <p className="pt-4 c-dark-grey fs-14">
                    Our platform has an easy-to-use interface. It offers many airlines and affordable fares, helping you find the best travel deals.
                    </p>
                    <p className="pt-4 c-dark-grey fs-14">
                    Cleartrip helps you compare prices for last-minute trips and planned vacations. You can choose the best options and book quickly and safely. Whether you're flying for business or leisure, our efficient search engine and user-friendly experience make booking flights effortless.
                    </p>
                </div>
                <HomePageFaq showFlightsFaq={true} />
                <FAQ data={FLIGHTSFAQData}/>
                <Spacer className="pt-8" />

                <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Disclaimer</h3>
                <ListCreator key={0} data={flightsDiscalimerData} />
            </>
            ) :(<>
                <div>
                    <h3 className="fw-600 c-neutral-900" style={{fontSize:'18px'}}>About Cleartrip</h3>
                    <p className="pt-4 c-dark-grey fs-14">Launched in July 2006, <strong>Cleartrip Pvt Ltd</strong>., a Flipkart company, has emerged as India's fastest-growing online travel technology company. In April 2021, Flipkart acquired a majority stake in Cleartrip. Cleartrip recently emerged as the no. 2 OTA player as per a study by VIDEC. With an aggressive plan to emerge as a leading innovator in the industry, Cleartrip is on its way to building a differentiated value proposition for its customers looking for end-to-end travel solutions. With industry-first offerings including <a href="/lp/ct-flex" className='td-none'>ClearChoice Plus</a>, <a href="/lp/ct-flexmax" className='td-none'>ClearChoice Max</a> and <a href="/lp/cfnr#:~:text=CFNR%20will%20give%20you%20an,What%20is%20Covered?" className='td-none'>Cancel for No Reason</a>', Cleartrip has a clear vision to provide innovative solutions in the OTA segment. Combining intuitive products with a customer-centric approach and a wide selection of flights, hotels, buses and holiday packages, Cleartrip brings a unique selling point to the market, offering its customers convenience, choice, competitive prices, and premium content.</p>
                </div>
                <div>
                    <h3 className=" fw-600 c-neutral-900 pt-5" style={{fontSize:'18px'}}>How Does Cleartrip Help You Plan Your Travel?</h3>
                    <p className="pt-4 c-dark-grey fs-14">
                    <strong>Cleartrip </strong> offers a collection of everything you need for a perfect trip. All can be done simultaneously, from flights to taxis to hotel bookings. This is how planning flights and hotels with Cleartrip gives you a clear edge:-
                    </p>
                    {ctFlightsFeatures.map((feature) => {
                        return (
                            <div className='pt-3'>
                                <span className='fs-14 fw-600 c-neutral-900'>{feature.heading}:</span>&nbsp;
                                <span className='c-dark-grey fs-14'>{feature.value}</span>
                            </div>
                        )
                    })}
                </div>
                <HomePageFaq showFlightsFaq={true} />
                <FAQ data={HOMEFAQData}/>
                <Spacer className="pt-8" />
                <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Disclaimer</h3>
                <p className="pt-4 c-dark-grey fs-14">
                In addition to the above, the data presented here cannot be assumed reliable; instead, it may vary over time in response to changing demands.
                </p>
            </>)}
            
            <Container height="60px" />
            <Container className="pt-15 mt-10" />
        </>
    )
}

export default FAQs